import Cmp from './modules/cmp';
import Commercial from './modules/commercial';
import SharingTools from './modules/sharingtools';

const modules = {
  Cmp,
  Commercial,
  SharingTools
};

var elements = document.querySelectorAll('.joomla-script-options');
if (elements.length > 0) {
  var options = JSON.parse(elements[0].text || elements[0].textContent);
  options.modules.forEach((module) => {
    if (modules[module.name]) {
      new modules[module.name](module.options);
    }
  });
}
